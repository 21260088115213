import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../../components/carousel'
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Hat Channels & Smurf Conduit' },
    { img: data.i2, caption: 'Hat Channels under Epicore MSR' },
    { img: data.i3, caption: 'Hat Channels under Epicore MSR' },
    { img: data.i4, caption: 'Soffit Framing Along Side of Room' },
    { img: data.i5, caption: 'Suspended Ceiling Grid' },
    { img: data.i6, caption: 'Suspended Drywall Ceiling' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: Ceiling Treatment - Hat Channels and Drywall" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>Ceiling Treatment - Hat Channels and Drywall</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>A common approach is to shoot (PAF’s) metal hat channels to the bottom side of the
          EPICORE MSR deck after the concrete has been poured and then screw drywall to the hat
          channels (Note that the drywall is not required to achieve the U.L. rating).  In an
          apartment or condo project with a lot of overhead electrical, it is preferable to use
          1.5” or 2” hat channels so that you can run the electrical underneath the EPICORE MSR
          and have enough plenum depth for the shielded cable, conduit, and electrical boxes.</p>

          <p>You can also hang a metal ceiling grid on wire and screw the drywall to the metal
          grid. This gives you more plenum space for ducts, sprinkler pipe, etc. The STC and
          IIC  rating can be slightly higher with a suspended drywall ceiling as well.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/01-CEILI330_(34246).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/02-CEILI410_(40273).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/03-CEILI400_(40271).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/04-CEILI560_(42869).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/05-CEILI620_(43288).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/gallery/06-CEILI440_(40690).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
